<template>
  <section>
    <div class="form-header">
      <h1>Reset your Password</h1>
      <h3>Step 3: Enter your security code</h3>
    </div>
    <hr>
    <div>
    <b-form @submit="onSubmit" v-if="show" autocomplete="off">
      <div id="form-info">
        <p>{{text1}}</p>
        <p>{{text2}}</p>
      </div>
      <br>
      <b-form-group id="securityCodeLabel"
                    label="Security Code:"
                    label-for="securityCode"
                    description="This is the security code that you received via text message or non-work email">
        <b-form-input id="securityCode"
                      type="text"
                      v-model="resetCode"
                      required
                      placeholder="Security Code">
        </b-form-input>
      </b-form-group>
      <div>
        <div class="buttons">
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="button" @click="onBack" variant="primary">Back</b-button>
          <app-spinner v-if="spinner" ></app-spinner>
        </div>
      </div>
    </b-form>
      <!-- Modal Component -->
      <b-modal ref="alertModal" :title=alertHeader :ok-only="true">
        <p class="my-4">{{alertText}}</p>
        <div slot="modal-footer" class="w-100">
          <b-button v-if="alertNav==='home'" size="sm" class="float-right" variant="primary" @click="navigateHome()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='resetPassword'" size="sm" class="float-right" variant="primary" @click="navigateResetPassword()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='here'" size="sm" class="float-right" variant="primary" @click="navigateHere()">
            Okay
          </b-button>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
/***************************************************
   * The FormPR3 component
   *
   * Page 3 of the Password Reset Orchestration
   *
   * Validates otpCode before password reset
   * Calls validateOTPCode api
   *
   ***************************************************/

import { mapGetters, mapActions } from "vuex";
import Spinner from '../Spinner.vue'

export default {
  name: 'FormPWR3',
  components: {
    'app-spinner': Spinner
  },
  data () {
    return {
      alertHeader: '',
      alertText: '',
      alertNav: 'here',
      text1: 'You should have received a password reset code, enter it below.',
      text2: 'If you did not receive a reset code, click "Back" to return to device selection.',
      show: true,
      localFormData: {},
      spinner: false
    }
  },
  computed: {
    ...mapGetters('formData', ['getSessionKey']),
    resetCode: {
      get () { return this.$store.state.formData.formData.resetCode },
      set (value) { this.$store.commit('formData/updateResetCode', value) }
    },
  },
  methods: {
    ...mapActions('formOrchestration', ['updateCurrentForm']),
    ...mapActions('formData', ['clearData', 'setSessionKey']),
    ...mapActions('localApi',['checkOtpCode']),
    async onSubmit (evt) {
      evt.preventDefault();
      this.spinner = true;

      // Validate OTP Code
      let results = await this.checkOtpCode({
        sessionKey: this.getSessionKey,
        otpCode: this.resetCode
      })
      if (results.result.toUpperCase() === 'FAILURE') {
        let failMessage;
        if (results.resultMessage) {
          failMessage = results.resultMessage + '\n\n If you feel you reached this message in error, please call the Help Desk for assistance.'
        } else {
          failMessage = 'General Failure: Please call the Help Desk for assistance.'
        }
        this.spinner = false;
        this.showAlertModal('Request Failure', failMessage, 'here')
      } else {
        this.spinner = false;
        this.updateCurrentForm('FormPWR4')
      }
    },
    onBack (evt) {
      evt.preventDefault();
      this.spinner = false;
      this.updateCurrentForm('FormPWR2')
    },
    showAlertModal (header, text, nav) {
      this.alertNav = nav;
      this.alertHeader = header;
      this.alertText = text;
      this.$refs.alertModal.show()
    },
    hideAlertModal () {
      this.$refs.alertModal.hide()
    },
    navigateHome () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/')
    },
    navigateResetPassword () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/passwordReset')
    },
    navigateHere () {
      this.hideAlertModal()
    }
  }
}
</script>

<style scoped>
  form-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0;
  }

  h1 {
    font-weight: bold;
    color: #000;
  }

  h3 {
    color: #4A4A4A;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    font-weight: bold;
    color: #000;
  }

  .form-control {
    background-color: #FFF;
    margin-bottom: 20px;
  }

  button {
    margin: 15px 0px;
    background-color: #5487BA;
    width: 100px;
    font-weight: bold;
  }

  button:hover {
    background-color: #123A5D;
  }

  #form-info {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    text-align: left;
  }

  @media only screen and (max-width: 650px) {
    .form-group {
      flex-direction: column;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    button {
      width: 300px;
    }
  }
</style>
