/****************************************************
 * Host object for the forms and their current state
 *
 ****************************************************/

let CryptoJS = require('crypto-js');
let AesUtil = require('../../helpers/aesUtil');
let clientServices = require('../../clientServices.js');

// Helper functions:
function determineMinPasswordLength () {
  return 8;
}
function determineMaxPasswordLength () {
  return 20;
}

function determinePasswordRules () {
  let ruleLines = [];
  let minPasswordLength = determineMinPasswordLength();
  let maxPasswordLength = determineMaxPasswordLength();

  ruleLines[0] = 'Password must be at least '+minPasswordLength+' characters long, but less than '+maxPasswordLength+'.';
  ruleLines[1] = 'Password must not contain first or last names.';
  ruleLines[2] = 'Password must not contain username.';
  ruleLines[3] = 'Password must have two alphabetic characters.';
  ruleLines[4] = 'Password must have one numeric character.';
  ruleLines[5] = 'Password must have one lowercase letter.';
  ruleLines[6] = 'Password must have one uppercase letter.';

  return ruleLines;
}

// initial state
const state = {
  sessionKey: ''
};

// getters
const getters = {
  getSessionKey: function (state) {
    return state.sessionKey
  }
};

// mutations
const mutations = {
  setSessionKey: function  (state, sessionKeyIn) {
    state.sessionKey = sessionKeyIn
  }
};

// actions
const actions = {
  setSessionKey: ({ commit }, payload) => commit('setSessionKey', payload),
  newSessionKey: async ({commit}) => {
    let sessionKey = await clientServices.getSessionKey();
    commit('setSessionKey', sessionKey)
    return sessionKey
  },
  encryptPassword: function  (context, {sessionKey, password}) {
    // Encryption method calls
    const aesUtil = new AesUtil(128, 1000);
    const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    const newCipherText = aesUtil.encrypt(salt, iv, sessionKey, password);
    const newAesPassword = (iv + '::' + salt + '::' + newCipherText);
    let encPassword = btoa(newAesPassword);
    return btoa(encPassword)
  },
  getPasswordRules: function  () {
    return determinePasswordRules()
  },
  validateDate: function  (context, localDate) {
    // Check Date format
    //remove non-numeric characters
    localDate = localDate.replace(/\D/g,'');
    // Validate that it is 8 numeric characters long
    if (localDate.substring(4, 5) === '-') {
      localDate = localDate.substring(5, 7) + localDate.substring(8, 10) + localDate.substring(0, 4)
    }
    if(localDate.length === 8) {
      try {
        //Validate that this is a valid date
        let date = new Date(localDate.substring(0,2)+'/'+localDate.substring(2,4)+'/'+localDate.substring(4,8));
        return (date instanceof Date && !isNaN(date.valueOf()));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  },
  validatePasswordRules: function  (context, { password, confirmPassword }) {
    // Determine password length requirement
    let minPasswordLength = determineMinPasswordLength();
    let maxPasswordLength = determineMaxPasswordLength();
    let ruleLines = determinePasswordRules();
    let rules = '';
    for (let i = 0; i < ruleLines.length; i++) {
      rules = rules + ruleLines[i] + '\n'
    }

    // Validate against rules
    let matching = false;
    if (password === confirmPassword) {
      matching = true
    }
    let oneNumeric = false;
    let oneUpperCase = false;
    let oneLowerCase = false;
    let twoAlpha = 0;
    //const specialChars = new RegExp(/[~`!#$%^&*+=\-[\]\\';,/{}|":<>?]/);
    let i = 0;
    while (i <= password.length - 1) {
      let character = password.charAt(i);
      if (!isNaN(character * 1)) {
        oneNumeric = true
      } else {
        if (character === character.toUpperCase()) {
          oneUpperCase = true
        }
        if (character === character.toLowerCase()) {
          oneLowerCase = true
        }
        if(/[a-zA-Z]/.test(character)){
          twoAlpha = twoAlpha+1;
        }
      }
      i++
    }

    // Return message and status
    if (password.length >= minPasswordLength &&
        password.length < maxPasswordLength &&
        oneNumeric &&
        oneUpperCase &&
        oneLowerCase &&
        twoAlpha >= 2 &&
        matching) {
      return { status: true, message: null }
    } else {
      let errorMessage;
      if (matching === false) {
        errorMessage = 'Passwords entered do not match. Please try again.'
      } else if (password.length < minPasswordLength || password.length >= maxPasswordLength || twoAlpha >= 2 || oneNumeric === false || oneUpperCase === false || oneLowerCase === false) {
        errorMessage = 'Password entered does not meet criteria. Please Try again.'
      } else {
        errorMessage = 'General Validation Failure. Please Try again.\n\nIf you continue to receive this message, please contact Login support at the link below.'
      }
      return {status: false, message: errorMessage}
    }
  },
  getNotification: function  () {
    return clientServices.getNotification();
  },
  getLinkLists: function  () {
    return clientServices.getLinkList();
  },
  resetPassword:function  (context, data) {
    return clientServices.resetPassword(data);
  },
  validateAccount: function  (context, data) {
    return clientServices.validateAccount(data.params);
  },
  getAccountData: function  (context, data) {
    return clientServices.getAccountData(data.params);
  },
  accountLookup: function  (context, data) {
    return clientServices.accountLookup(data);
  },
  updateContactData: function  (context, data) {
    return clientServices.updateAccount(data.params);
  },
  checkCaptcha: function (context, data) {
    return clientServices.checkCaptcha(data);
  },
  sendOtpCode: async (context,data) => {
    let results = clientServices.sendOtpCode(data);
    return results;
  },
  checkOtpCode: async (context,data)=> {
    let results = clientServices.checkOtpCode(data);
    return results;
  },
   unlockAccount: async (context, data)=> {
    let results =  clientServices.unlockAccount(data);
    return results;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
