<template>
  <b-button @click="navigate" :variant="currentStepFlag" :disabled="disabledFlag">
    <strong>{{stepNumber}}</strong>
    <br>
    <div v-if="windowWidth > 850 || windowWidth < 500">
      {{stepName}}
    </div>
  </b-button>
</template>

<script>
/***************************************************
 * The FormLink component
 *
 * Buttons for FormView component
 *
 * Bound to passed in step information
 *
 * Adds navigation to the formView and Orchestration
 ***************************************************/

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'form-link',
  data () {
    return {
      windowWidth: 0
    }
  },

  props: {
    form: { type: String, required: true },
    orchestration: { type: String, required: true },
    stepName: { type: String, required: true },
    stepNumber: { type: String, required: true },
    step: { type: Number, required: true }
  },
  computed: {
    ...mapGetters('formOrchestration', ['getCurrentStep']),
    disabledFlag () {
      return this.step > this.getCurrentStep;
    },
    currentStepFlag () {
      if (this.step === this.getCurrentStep) {
        return 'primary'
      } else {
        return 'secondary'
      }
    }
  },
  methods: {
    ...mapActions('formOrchestration', ['updateCurrentForm']),
    navigate (evt) {
      evt.preventDefault();
      this.updateCurrentForm(this.form);
    }
  },
  mounted () {
    this.windowWidth = window.innerWidth;
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      })
    })
  }
}
</script>

<style scoped>
</style>
