<template>
  <section>
    <div class="form-header">
      <h1>Account Recovery Information</h1>
    </div>
    <div>
      <b-form @submit="onSubmit" v-if="show" autocomplete="off">
        <div id="form-header">
          <p>{{text}}</p>
        </div>
        <section class="main-form">
          <br>
          <b-container fluid>
            <b-row class="my-1">
              <b-col sm="5">
                <label>Mobile Phone:</label>
              </b-col>
              <b-col sm="7">
                <b-form-input id="input-mobile-phone" size="sm" v-model="cellPhone" required placeholder="Mobile Phone" type="text"></b-form-input>
              </b-col>
            </b-row>
          </b-container>
          <br>
          <section id="info-use">
            <p>This information will be used for future password reset requests.</p>
          </section>
        </section>
        <div>
          <b-button type="submit" variant="primary" :disabled="spinner">Save and Finish</b-button>
          <app-spinner v-if="spinner"/>
        </div>
      </b-form>
      <!-- Modal Component -->
      <b-modal ref="alertModal" :title=alertHeader :ok-only="true">
        <p class="my-4">{{alertText}}</p>
        <div slot="modal-footer" class="w-100">
          <b-button v-if="alertNav==='home'" size="sm" class="float-right" variant="primary" @click="navigateHome()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='resetPassword'" size="sm" class="float-right" variant="primary" @click="navigateResetPassword()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='here'" size="sm" class="float-right" variant="primary" @click="navigateHere()">
            Okay
          </b-button>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
  /* eslint-disable no-useless-escape */

  /***************************************************
   * The FormAC4 component
   *
   * Page 4 of the Account Claim Orchestration
   *
   * Calls updateAccount api
   *   Updates CellPhone
   ***************************************************/


  import { mapGetters, mapMutations, mapActions } from 'vuex'
  import Spinner from '../Spinner.vue'

  function validatePhone (_phone) {
    let phone = _phone == null ? "" : _phone.replace(/[^0-9]/g, '');
    return phone.length >= 10
  }

  export default {
    name: 'FormAC4',
    components: {
      'app-spinner': Spinner
    },
    data () {
      return {
        alertHeader: '',
        alertText: '',
        alertNav: 'here',
        text: 'Please provide any additional information for your account for future password resets.',
        show: true,
        localFormData: {},
        spinner: false
      }
    },
    computed: {
      ...mapGetters('formData',['getSessionKey', 'getFormDataCellPhone']),
      cellPhone:{
        get () { return this.$store.state.formData.formData.cellPhone },
        set (value) { this.$store.commit('formData/updateFormDataCellPhone', value) }
      },
    },
    methods: {
      ...mapMutations('formOrchestration',['updateCurrentForm']),
      ...mapMutations('formData',['clearData']),
      ...mapActions('localApi',['updateContactData']),
      async onSubmit (evt) {
        evt.preventDefault();
        this.spinner = true;
        // Validate phone number are correct
        let ValidationError = false;
        if ( this.cellPhone && !(this.cellPhone === '') && !validatePhone(this.cellPhone)) {
          ValidationError = true
        }

        //Must enter a cellphone
        let resetMethodError = false;
        if (this.cellPhone == null || this.cellPhone === '') {
          resetMethodError = true
        }

        let message = 'General Validation Error';
        if (ValidationError === false && resetMethodError === false) {

          let parameters = {
            params: {
              sessionKey: this.getSessionKey,
              cellPhone: this.cellPhone == null ? "" : this.cellPhone.replace(/[^0-9]/g, '')
            }
          };

          let results = await this.updateContactData(parameters);
          if (results.result.toUpperCase() === 'FAILURE') {
            let failMessage;
            if (results.resultMessage) {
              failMessage = results.resultMessage
            } else {
              failMessage = 'General Failure'
            }
            this.spinner = false;
            this.showAlertModal('Request Failure', failMessage, 'here')
          } else {
            // HandleNavigation
            this.spinner = false;
            this.updateCurrentForm('FormAC4')
          }
        } else {
          if (resetMethodError) {
            message = 'Please enter either a cell phone; this will be used for password management.'
          } else {
            message = 'Please validate the cell phone entered.\n\nPhone numbers should have 10+ digits'
          }
          this.spinner = false;
          this.showAlertModal('Warning', message, 'here')
        }
      },
      showAlertModal (header, text, nav) {
        this.alertNav = nav;
        this.alertHeader = header;
        this.alertText = text;
        this.$refs.alertModal.show()
      },
      hideAlertModal () {
        this.$refs.alertModal.hide()
      },
      navigateHome () {
        this.hideAlertModal();
        this.clearData();
        this.$router.push('/')
      },
      navigateResetPassword () {
        this.hideAlertModal();
        this.clearData();
        this.$router.push('/passwordReset')
      },
      navigateHere () {
        this.hideAlertModal()
      }
    }
  }
</script>

<style scoped>
  .form-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
  }

  h1 {
    font-weight: bold;
    color: #000;
    font-size: 25px;
  }

  h3 {
    color: #4A4A4A;
    font-size: 15px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    font-weight: bold;
    color: #000;
  }

  button {
    margin: 15px 0px;
    background-color: #5487BA;
    width: 150px;
    font-weight: bold;
  }

  button:hover {
    background-color: #123A5D;
  }

  #additionalInfoText {
    height: 75px;
  }

  .form-control {

    background-color: #FFF;
  }

  #info-use {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 13px;
    padding: 10px;
    text-align: left;
  }

  @media only screen and (max-width: 650px) {
    .form-group {
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 650px) {
    form {
      width: 650px;
    }
  }
</style>
