
/****************************************************
 * Host object for the googleAPI and their current state
 *
 ****************************************************/
import config from '../../config';
let clientServices = require('../../clientServices.js');

// initial state
const state = {
  siteKey: config.GOOGLE_SITE_KEY
};

// getters
const getters = {
  getSiteKey: function  (state) {
    return state.siteKey
  }
};

// actions
const actions = {
  // eslint-disable-next-line no-unused-vars
  checkCaptcha: async ({commit}, captchaResponse) => {

    // Check Captcha
    let valid = await clientServices.checkCaptcha(captchaResponse)
    return valid;
  }
};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
