<template>
  <section>
    <div class="form-header">
      <h2>Account Information</h2>
    </div>
    <br>
    <b>Your Account has been claimed</b>, please keep the following for your records:
    <br>
    <div>
      <b-form @submit="onSubmit" autocomplete="off">
        <section class="main-form">
          <br>
          <b-container fluid>
            <b-row class="my-1">
              <b-col sm="5">
                <label>Username:</label>
              </b-col>
              <b-col sm="7">
                <b-form-input id="input-username" size="sm" v-model="username" type="text" disabled></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="5">
                <label >Email Address:</label>
              </b-col>
              <b-col sm="7">
                <b-form-input id="input-emailAddress" size="sm" v-model="emailAddress" type="text" disabled></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col sm="5">
                <label >Employee Number:</label>
              </b-col>
              <b-col sm="7">
                <b-form-input id="input-employeeNumber" size="sm" v-model="employeeNumber" type="text" disabled></b-form-input>
              </b-col>
            </b-row>
          </b-container>
          <br>
        </section>
        <div>
          <b-button type="submit" variant="primary">Done</b-button>
          <app-spinner v-if="spinner"/>
        </div>
      </b-form>
    </div>
  </section>
</template>

<script>
/***************************************************
   * The FormAC4 component
   *
   * Final Page of the Account Claim Orchestration
   *
   * Displays account information
   *
   ***************************************************/

import {mapActions, mapGetters} from 'vuex'
import Spinner from '../Spinner.vue'

export default {
  name: 'FormAC4',
  components: {
    'app-spinner': Spinner
  },
  data () {
    return {
      spinner: false
    }
  },
  computed: {
    ...mapGetters('formData',['getSessionKey','getFormDataUsername','getFormDataEmailAddress','getFormDataEmployeeId']),
    username:{
      get () { return this.$store.state.formData.formData.username },
      set (value) { this.$store.commit('formData/updateFormDataUsername', value) }
    },
    emailAddress: {
      get () { return this.$store.state.formData.formData.emailAddress },
      set (value) { this.$store.commit('formData/updateFormDataEmailAddress', value) }
    },
    employeeNumber: {
      get () { return this.$store.state.formData.formData.employeeId },
      set (value) { this.$store.commit('formData/updateFormDataEmployeeId', value) }
    },
  },
  methods: {
    ...mapActions('formOrchestration', ['updateCurrentForm']),
    ...mapActions('formData', ['clearData']),
    ...mapActions('localApi',['getAccountData']),
    onSubmit (evt) {
      evt.preventDefault()
      this.spinner = true
      this.clearData()
      this.spinner = false
      this.$router.push('/')
    },
    async getAccountInformation() {
      this.spinner = true
      //Get Account Data
      try {
        let parameters = {
          params: {
            sessionKey: this.getSessionKey
          }
        };
        let accountData = await this.getAccountData(parameters);
        this.emailAddress = accountData.data.emailAddress;
        this.employeeNumber = accountData.data.employeeId;
        this.username = accountData.data.username;
      } catch(err){
        // eslint-disable-next-line no-console
        console.log(err);
      }
      this.spinner = false
    }
  },
  async mounted () {
    await this.getAccountInformation();
  }
}
</script>

<style scoped>
  #setupRecoveryLink{
    float: right;
  }
  .form-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
  }

  h1 {
    margin-right: 50px;
    font-weight: bold;
    color: #000;
    font-size: 25px;
  }

  h3 {
    color: #4A4A4A;
    font-size: 15px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    font-weight: bold;
    color: #000;
    margin-bottom: 0.5rem;
  }

  button {
    margin: 15px 0px;
    background-color: #5487BA;
    width: 100px;
    font-weight: bold;
  }

  button:hover {
    background-color: #123A5D;
  }

  .disclaimer {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 13px;
    padding: 10px 0;
  }

  p {
    margin-left: 10px;
    margin-bottom: 5px;
  }

 @media only screen and (max-width: 870px) {
    .form-group {
      flex-direction: column;
    }
  }


  b {
    font-weight: bold;
  }

</style>
