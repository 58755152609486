<template>
  <div>
  <div id="app">
    <app-header id="header"></app-header>
    <app-message v-if="notification!=null" id="message" :message="notification"></app-message>
    <router-view/>
    <app-footer id="footer"></app-footer>
  </div>
  <div id="fill"></div>
  </div>
</template>

<script>

import Header from './Header.vue'
import Notification from './Notification.vue'
import Footer from './Footer.vue'
import config from './../config.js'

const configNotification = config.NOTIFICATION || null;

export default {
  name: 'App',
  data () {
    return {
      notification: configNotification
    }
  },

  components: {
    'app-header': Header,
    'app-message': Notification,
    'app-footer': Footer
  }
}
</script>

<style>
  #fill {
    height:700px;
    background-color: #e7e7e7;
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  #header {
    height: 70px;
  }

  #footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  @media only screen and (max-width: 650px) {
    #footer {
      display: none;
    }
  }
</style>
