let CryptoJS = require('crypto-js');

// Define AesUtil Class
const AesUtil = function (keySize, iterationCount) {
  this.keySize = keySize / 32;
  this.iterationCount = iterationCount
};

AesUtil.prototype.getRandomb64String = (num, den)=>{
  return CryptoJS.lib.WordArray.random(num / den).toString(CryptoJS.enc.Hex);
};

AesUtil.prototype.atob = (b64Encoded)=>{
  return Buffer.from(b64Encoded, 'base64').toString()
};

AesUtil.prototype.btoa = (text)=>{
  return Buffer.from(text).toString('base64')
};

AesUtil.prototype.generateKey = function (salt, passPhrase) {
  return CryptoJS.PBKDF2(passPhrase,CryptoJS.enc.Hex.parse(salt),{ keySize: this.keySize, iterations: this.iterationCount })
};

AesUtil.prototype.encrypt = function (salt, iv, passPhrase, plainText) {
  const key = this.generateKey(salt, passPhrase);
  const encrypted = CryptoJS.AES.encrypt(
    plainText,
    key,
    { iv: CryptoJS.enc.Hex.parse(iv) });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
};

AesUtil.prototype.decrypt = function (salt, iv, passPhrase, cipherText) {
  const key = this.generateKey(salt, passPhrase);
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(cipherText)
  });
  const decrypted = CryptoJS.AES.decrypt(
    cipherParams,
    key,
    { iv: CryptoJS.enc.Hex.parse(iv) });
  return decrypted.toString(CryptoJS.enc.Utf8)
};


module.exports = AesUtil;
