import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './components/App.vue'
import router from './router/router'
import store from './store/vuex'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Handle Logging
// eslint-disable-next-line no-console
Vue.prototype.$log = console.log.bind(console);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app');
