<template>
  <footer>
    <div>
      <div class="footer-text">
        <p>© 2020 All Rights Reserved </p>
      </div>
    </div>
  </footer>
</template>

<script>

/***************************************************
 * The Footer component
 *
 ***************************************************/

export default {
  name: 'Footer'
}
</script>

<style scoped>
  footer {
    background-color: #e7eee8;
    display: flex;
    color: #1F578A;
    padding-left: 20px;
    align-items: center;
  }

  .footer-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 20px;
  }

  p {
    margin: 0px;
    font-size: 12px;
  }


</style>
