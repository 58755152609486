<template>
  <section>
    <div class="form-header">
      <h1>Register</h1>
    </div>
    <div>
      <b-form @submit="onSubmit" v-if="show" autocomplete="off">
        <b-form-group id="firstNameLabel"
                      label="First Name:"
                      label-for="firstName">
          <b-form-input id="firstName"
                        required
                        v-model="userId"
                        placeholder="First Name">
          </b-form-input>
        </b-form-group>
        <b-form-group id="lastNameLabel"
                      label="Last Name:"
                      label-for="lastName">
          <b-form-input id="lastName"
                        type="text"
                        v-model="lastName"
                        required
                        placeholder="Last Name">
          </b-form-input>
        </b-form-group>
        <b-form-group id="streetNumber"
                      label="Address Street Number:"
                      label-for="streetNumber">
          <b-form-input id="streetNumber"
                        required
                        v-model="streetNumber"
                        placeholder="Street or P.O. Box Number">
          </b-form-input>
        </b-form-group>
        <b-form-group id="personalEmailLabel"
                      label="Personal Email:"
                      label-for="personalEmail">
          <b-form-input id="personalEmail"
                        required
                        v-model="personalEmail"
                        placeholder="Personal Email">
          </b-form-input>
        </b-form-group>
        <div class="additional-info">
          <div id="recaptcha" class="g-recaptcha"></div>
          <div class="disclaimer">
            <p>To claim your corporate account, please fill out and "submit" the information for each step of the process.</p>
            <p>Please be sure to complete all 4 steps and retrieve your account information.</p>
          </div>
        </div>
        <div>
          <b-button type="submit" variant="primary">Next</b-button>
          <app-spinner v-if="spinner" ></app-spinner>
        </div>
      </b-form>
      <!-- Modal Component -->
      <b-modal ref="alertModal" :title=alertHeader :ok-only="true">
        <p class="my-4">{{alertText}}</p>
        <div slot="modal-footer" class="w-100">
          <b-button v-if="alertNav==='home'" size="sm" class="float-right" variant="primary" @click="navigateHome()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='resetPassword'" size="sm" class="float-right" variant="primary" @click="navigateResetPassword()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='here'" size="sm" class="float-right" variant="primary" @click="navigateHere()">
            Okay
          </b-button>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
  /* eslint-disable no-undef */

  /***************************************************
   * The FormAC1 component
   *
   * Page 1 of the Account Claim Orchestration
   *
   * Calls LookupAccountData api
   ***************************************************/

  import { mapGetters, mapActions } from 'vuex'
  import Spinner from '../Spinner.vue'
  let clientServices = require('../../clientServices.js');


  function validateEmail (_email) {
    _email = _email == null ? "" : _email;
    // eslint-disable-next-line no-useless-escape
    let regx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regx.test(String(_email).toLowerCase())
  }

  export default {
    name: 'FormAC1',
    components: {
      'app-spinner': Spinner
    },
    data () {
      return {
        widgetId: 0,
        captchaResponse: '',
        alertHeader: '',
        alertText: '',
        alertNav: 'here',
        show: true,
        localFormData: {},
        spinner: false
      }
    },
    computed: {
      ...mapGetters('formData', ['getSessionKey', 'getFormDataLastName','getFormDataUserId','getFormDataStreetNumber', 'getFormDataPersonalEmail']),
      ...mapGetters('google', ['getSiteKey']),
      lastName:{
        get () { return this.$store.state.formData.formData.lastName },
        set (value) { this.$store.commit('formData/updateFormDataLastName', value) }
      },
      userId: {
        get () { return this.$store.state.formData.formData.userId },
        set (value) { this.$store.commit('formData/updateFormDataUserId', value) }
      },
      streetNumber: {
        get () { return this.$store.state.formData.formData.streetNumber },
        set (value) { this.$store.commit('formData/updateFormDataStreetNumber', value) }
      },
      personalEmail: {
        get () { return this.$store.state.formData.formData.personalEmail },
        set (value) { this.$store.commit('formData/updateFormDataPersonalEmail', value) }
      }
    },
    methods: {
      ...mapActions('formOrchestration', ['updateCurrentForm']),
      ...mapActions('formData', ['clearData', 'setSessionKey']),
      ...mapActions('localApi',['validateAccount','checkCaptcha']),
      initReCaptcha: function () {
        let siteKey = this.getSiteKey;
        let self = this;
        setTimeout(function () {
          if (typeof grecaptcha === 'undefined') {
            self.initReCaptcha()
          } else {
            this.widgetId = grecaptcha.render('recaptcha', {
              sitekey: siteKey,
              size: 'compact normal',
              badge: 'inline',
              callback: self.saveCaptcha
            })
          }
        }, 100)
      },
      saveCaptcha (token) {
        this.captchaResponse = token
      },
      resetCaptcha () {
        grecaptcha.reset(this.widgetId)
      },
      async onSubmit (evt) {
        evt.preventDefault();
        this.spinner = true;
        // validation work
        let errorMessage = '';

        // Remove non-numeric characters
        this.streetNumber = this.streetNumber.trim().split(' ')[0].trim().replace(/\D/g, '');

        let captchaClicked = true;
        if (this.captchaResponse === '') {
          captchaClicked = false
        }

        if (validateEmail(this.personalEmail) === true) {
          if (captchaClicked === true) {
            let captchaParams = {
              params: {
                sessionKey: this.getSessionKey,
                response: this.captchaResponse
              }
            };

            let captchaValidation = await this.checkCaptcha(captchaParams);
            if (captchaValidation === true) {
              let parameters = {
                params: {
                  userId: this.userId,
                  lastName: this.lastName,
                  streetNumber: this.streetNumber,
                  personalEmail: this.personalEmail,
                  sessionKey: this.getSessionKey
                }
              };
              let accountData = await this.validateAccount(parameters);
              if (accountData.result.toUpperCase() === 'FAILURE') {
                let failMessage;
                if (accountData.resultMessage) {
                  failMessage = accountData.resultMessage
                } else {
                  failMessage = 'General Failure: there has been an error on the remote server, please try your request again in a few minutes.'
                }
                this.resetCaptcha();
                this.spinner = false;
                this.showAlertModal('Request Failure', failMessage, 'here');

              } else {

                // HandleNavigation
                if (accountData.accountClaimed === true) {
                  this.resetCaptcha();
                  this.spinner = false;
                  this.showAlertModal('Warning', 'This account has already been claimed. If you do not know your password and need to reset it, click "reset your password" on home screen.', 'home')

                } else if (accountData.result.toUpperCase() === 'SUCCESS') {
                  this.spinner = false;
                  this.updateCurrentForm('FormAC2')
                } else if (accountData.result.toUpperCase() !== 'SUCCESS') {
                  this.resetCaptcha();
                  this.spinner = false;
                  this.showAlertModal('Warning', 'Employee record is not currently \'Active\'. Please contact the Help Desk for assistance.', 'home')
                } else {
                  this.resetCaptcha();
                  this.spinner = false;
                  this.showAlertModal('Warning', 'Account was not found, please validate selection and Try again. If you feel you are receiving this message in error, please contact the Help Desk for assistance.', 'here')
                }
              }
            }
          } else {
            errorMessage = errorMessage + '\nPlease validate you\'re not a robot and try again.';
            if (this.widgetId === 0) {
              this.initReCaptcha()
            }
            this.spinner = false;
            this.resetCaptcha();
            this.showAlertModal('Warning', errorMessage, 'here')
          }
        } else {
          this.resetCaptcha();
          this.spinner = false;
          this.showAlertModal('Warning', 'Please validate email selection and Try again.', 'here')
        }
      },
      showAlertModal (header, text, nav) {
        this.alertNav = nav;
        this.alertHeader = header;
        this.alertText = text;
        this.$refs.alertModal.show()
      },
      hideAlertModal () {
        this.resetCaptcha();
        this.$refs.alertModal.hide()
      },
      navigateHome () {
        this.hideAlertModal();
        this.clearData();
        this.$router.push('/')
      },
      navigateResetPassword () {
        this.hideAlertModal();
        this.clearData();
        this.$router.push('/passwordReset')
      },
      navigateHere () {
        this.resetCaptcha();
        this.hideAlertModal()
      }
    },
    mounted: function () {
      this.initReCaptcha()
    },
    async created () {
      let sessionKey =  this.getSessionKey;
      // eslint-disable-next-line no-console
      console.log(sessionKey)

      if (sessionKey == null || sessionKey.length < 12 ) {
        sessionKey = await clientServices.getSessionKey();
        this.setSessionKey(sessionKey);
      }
    }
  }
</script>

<style scoped>
  .form-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
  }

  h1 {
    font-weight: bold;
    color: #000;
    font-size: 25px;
  }

  h3 {
    color: #4A4A4A;
    font-size: 15px;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    font-weight: bold;
    color: #000;
    margin-bottom: 0.5rem;
  }

  button {
    margin: 15px 0px;
    background-color: #5487BA;
    width: 100px;
    font-weight: bold;
  }

  button:hover {
    background-color: #123A5D;
  }

  .additional-info {
    display: flex;
    flex-direction: column;
  }

  .disclaimer {
    padding: 5px;
    background-color: #f5f5f5;
    width: 100%;
    font-size: 13px;
    margin-left: 10px;
  }

  @media only screen and (max-width: 650px) {

    .form-group {
      flex-direction: column;
    }

    .disclaimer {
      margin-left: 0;
    }
  }
</style>
