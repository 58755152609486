<template>
  <section id="main-view">
    <h1>User Account Management</h1>
    <br>

    <!-- Add Notifications -->
    <div id="notification-box">
      <h2>
        {{notification}}
      </h2>
    </div>
<br>
    <h2>Account Management Links:</h2>
    <br>
    <section class="options">
      <div class="option">

        <b-button :to="{ name: 'FormOrchestration', params: {orchestration: 'accountClaim' } }" variant="primary">Claim Account</b-button>
        <b-button :to="{ name: 'FormOrchestration', params: {orchestration: 'passwordReset' } }" variant="primary">Reset Password</b-button>
        <b-button :to="{ name: 'FormOrchestration', params: {orchestration: 'unlockAccount' } }" variant="primary">Unlock Account</b-button>
          <!-- Change to for loop on Links -->
          <ul id="example-1">
              <li v-for="item in links" :key="item.url">
                  <a :href="item.url">{{item.text}}</a>
              </li>
          </ul>
      </div>
    </section>
  </section>
</template>
<script>
/***************************************************
 * The Main component
 *
 * Root of the Orchestration options
 ***************************************************/

import { mapActions } from 'vuex'

export default {
  name: 'Main',
  data () {
    return {
      notification: null,
      links:[]
    }
  },
  props: {
    showEnvHeader: null,
  },
  methods: {
    ...mapActions('localApi', ['getNotification', 'getLinkLists'])
  },
  async created() {
    let notificationResult = await this.getNotification();
    if(notificationResult && notificationResult.result && notificationResult.result === "FAILURE") {
      this.notification = "Server Could not be contacted"
    } else {
      this.notification = notificationResult
    }
    let linkList = await this.getLinkLists();
    try {
      this.links = linkList.links;
    } catch(err){
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }
}
</script>

<style scoped>

  #notification-box{
    background-color: #c2693a;
    color: #662222;
    width: 760px;
  }

  #main-view {
    background-color: #E7E7E7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
  }

  .btn-primary {
    margin: 15px 0px;
    background-color: #5487BA;
    font-weight: bold;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal;
  }

  .btn-primary:hover {
    background-color: #123A5D;
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    max-width: 650px;
  }

  .option {
    margin: 0 15px;
    display: flex;
    flex-direction: column;

  }

  h1, h2, h6 {
    margin: 0;
  }

  h6 {
    font-size: 12px;
  }

  #EnvironmentHeader {
    background-color: chocolate;
    width: 100%;
    padding: 10px 50px;
  }
</style>
