<template>
  <div>
    <h1>Account Unlocked</h1>
    <hr>
    <div>
    <b-form @submit="onSubmit" v-if="show" autocomplete="off">
      <div id="form-info">
        <p>{{changeStatus}}</p>
      </div>
      <br>
      <br>
      <div>
        <b-button type="submit" variant="primary">Done</b-button>
        <app-spinner v-if="spinner" ></app-spinner>
      </div>
    </b-form>
      <!-- Modal Component -->
      <b-modal ref="alertModal" :title=alertHeader :ok-only="true">
        <p class="my-4">{{alertText}}</p>
        <div slot="modal-footer" class="w-100">
          <b-button v-if="alertNav==='home'" size="sm" class="float-right" variant="primary" @click="navigateHome()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='resetPassword'" size="sm" class="float-right" variant="primary" @click="navigateResetPassword()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='here'" size="sm" class="float-right" variant="primary" @click="navigateHere()">
            Okay
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
/***************************************************
   * The FormULA4 component
   *
   * Final Page of the Unlock Account Orchestration
   *
   * Displays Done status
   *
   ***************************************************/

import { mapActions } from "vuex";
import Spinner from '../Spinner.vue'

export default {
  name: 'FormULA4',
  components: {
    'app-spinner': Spinner
  },
  data () {
    return {
      alertHeader: '',
      alertText: '',
      alertNav: 'here',
      changeStatus: 'Account Unlocked Successfully',
      show: true,
      spinner: false
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('formOrchestration', ['updateCurrentForm']),
    ...mapActions('formData', ['clearData', 'setSessionKey']),
    onSubmit (evt) {
      evt.preventDefault();

      this.spinner = true;

      this.clearData();
      this.spinner = false;
      this.$router.push('/')
    },
    showAlertModal (header, text, nav) {
      this.alertNav = nav;
      this.alertHeader = header;
      this.alertText = text;
      this.$refs.alertModal.show()
    },
    hideAlertModal () {
      this.$refs.alertModal.hide()
    },
    navigateHome () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/')
    },
    navigateResetPassword () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/passwordReset')
    },
    navigateHere () {
      this.hideAlertModal()
    }
  }
}
</script>

<style scoped>
  h1 {
    margin-top: 50px;
    font-weight: bold;
    color: #000;
  }

  .form-control {
    background-color: #FFF;
  }

  .btn-primary {
    margin: 15px 0px;
    background-color: #5487BA;
    font-weight: bold;
  }

  .btn-primary:hover {
    background-color: #123A5D;
  }

  #form-info {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    text-align: center;
  }
</style>
