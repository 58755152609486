/****************************************************
 * The client application's object for accessing the server
 *
 ****************************************************/

let axios = require('axios');
let ApiPort = require('./config.js').API_PORT;
let ApiHost = require('./config.js').API_HOST;
let ApiProtocol = require('./config.js').API_PROTOCOL;
let ApiPath = require('./config.js').API_PATH;
let apiURL = ApiProtocol + '://' + ApiHost + ':' + ApiPort + '/'+ApiPath+'api/';

/******************************************************************************
 * Helper methods for calling APIs Rest Services
 ******************************************************************************/

const getToken = function(){
  return localStorage.getItem('token');
};

const removeToken = function(){
  return localStorage.removeItem('token');
};

const failureResults = function (err) {
  let results = {};
  if(err==='Error: Request failed with status code 401'){
    results['noAuth'] = true;
    removeToken();
  }
  results['result'] = 'FAILURE';
  results['resultMessage'] = 'Failure: Server did not respond.';
  return results
};

const restServiceGet = function (URL, params) {
  if (params) {
     params.headers= {
        Authorization: 'Bearer ' + getToken()
      };
    return axios.get(URL,params)
      .then(res => {
        return res.data
      }).catch((err) => {
        return failureResults(err)
      })
  } else {
    return axios
      .get(URL, {
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      }).then(res => {
        return res.data
      }).catch((err) => {
        return failureResults(err)
      })
  }
};

// eslint-disable-next-line no-unused-vars
const restServicePost = function (URL, params, data) {
  if(params && params.file){
    let formData = new FormData();
    formData.append('file', params.file);
    return axios.post(URL, formData, {headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + getToken()}}
      ).then(res => {
        return res.data;
      })
      .catch((err) => {
        return failureResults(err);
      })
  }
  else if (params) {
    params.token = getToken();
    return axios.post(URL, params,{
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    })
      .then(res => {
        return res.data;
      })
      .catch((err) => {
        return failureResults(err);
      })
  } else {
    return axios.post(URL,data,{
      headers: {
        Authorization: 'Bearer ' + getToken()
      }
    })
      .then(res => {
        return res.data;
      }).catch((err) => {
        return failureResults(err);
      })
  }
};

/******************************************************************************
 * Expose Service hooks to Client app
 ******************************************************************************/
module.exports = {

  getSessionKey () {
    return restServiceGet(apiURL + 'sessionKey');
  },

  checkCaptcha (data) {
    return restServiceGet(apiURL + 'checkCaptcha/',  data);
  },

  validateAccount (data) {
    return restServicePost(apiURL + 'validateAccount/', null,  data);
  },

  resetPassword (data) {
    return restServicePost(apiURL + 'resetPassword/', null,  data);
  },

  updateAccount (data) {
    return restServicePost(apiURL + 'updateContactInfo/', null,  data);
  },

  getAccountData (data) {
    return restServicePost(apiURL + 'getAccountData/', null,  data);
  },

  accountLookup (data) {
    return restServicePost(apiURL + 'accountLookup/', null,  data);
  },

  sendOtpCode (data) {
    return restServicePost(apiURL + 'sendOtpCode/', null,  data);
  },

  checkOtpCode (data) {
    return restServicePost(apiURL + 'checkOtpCode/', null,  data);
  },

  unlockAccount (data) {
    return restServicePost(apiURL + 'unlockAccount/', null,  data);
  },

  getNotification () {
    return restServiceGet(apiURL + 'notification/');
  },

  getLinkList () {
    return restServiceGet(apiURL + 'linkList/');
  }
};
