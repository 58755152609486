<template>
  <section class="main-view">
    <div class="form-header">
      <h1>Reset your Password</h1>
      <h3>Step 4: Set your new password</h3>
    </div>
    <hr>
    <b-form @submit="onSubmit" v-if="show" autocomplete="off">
      <section class="main-form">
        <b-form-group id="newPasswordLabel"
                      label="New Password:"
                      label-for="newPassword">
          <b-form-input id="newPassword"
                        type="password"
                        v-model="newPassword"
                        required
                        placeholder="New Password">
          </b-form-input>
        </b-form-group>
        <b-form-group id="confirmPasswordLabel"
                      label="Confirm Password:"
                      label-for="confirmPassword">
          <b-form-input id="confirmPassword"
                        type="password"
                        v-model="confirmPassword"
                        required
                        placeholder="Confirm Password">
          </b-form-input>
        </b-form-group>
        <section id="password-rules">
          <p>Please create your password above.</p>
          <br>
          <p>{{ruleLines[0]}}</p>
          <p>{{ruleLines[1]}}</p>
          <p>{{ruleLines[2]}}</p>
          <p>{{ruleLines[3]}}</p>
        </section>
        <br>
      </section>

      <div>
        <b-button type="submit" variant="primary">Submit</b-button>
        <app-spinner v-if="spinner"/>
      </div>
    </b-form>
    <!-- Modal Component -->
    <b-modal ref="alertModal" :title=alertHeader :ok-only="true">
      <p class="my-4">{{alertText}}</p>
      <div slot="modal-footer" class="w-100">
        <b-button v-if="alertNav==='home'" size="sm" class="float-right" variant="primary" @click="navigateHome()">
          Okay
        </b-button>
        <b-button v-if="alertNav==='resetPassword'" size="sm" class="float-right" variant="primary" @click="navigateResetPassword()">
          Okay
        </b-button>
        <b-button v-if="alertNav==='here'" size="sm" class="float-right" variant="primary" @click="navigateHere()">
          Okay
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
/***************************************************
 * The FormPR4 component
 *
 * Page 4 of the Password Reset Orchestration
 *
 * Calls resetPassword api
 *
 ***************************************************/

import { mapGetters, mapActions  } from 'vuex'
import Spinner from '../Spinner.vue'

export default {
  name: 'FormPWR4',
  components: {
    'app-spinner': Spinner
  },
  data () {
    return {
      alertHeader: '',
      alertText: '',
      alertNav: 'here',
      text: 'Please create your password above.\n\n' + this.rules,
      show: true,
      localFormData: {},
      spinner: false,
      ruleLines: [],
      newPassword: null,
      confirmPassword: null,
      rules: 'Password must be at least 8 characters and contain at least: ' +
        '\none uppercase letter, one lowercase letter, one numeric or special character.' +
        '\nPrevious passwords cannot be reused.'
    }
  },
  computed: {
    ...mapGetters('formData', ['getSessionKey']),
  },
  methods: {
    ...mapActions('formOrchestration', ['updateCurrentForm']),
    ...mapActions('formData',['clearData']),
    ...mapActions('localApi',['validatePasswordRules', 'encryptPassword', 'getPasswordRules','resetPassword']),
    async onSubmit (evt) {
      evt.preventDefault();
      this.spinner = true;

      let validPassword = await this.validatePasswordRules({
        password: this.newPassword,
        confirmPassword: this.confirmPassword
      });
      let errorMessage = '';
      if (validPassword.status === true) {
        // Call Soap action
        const encNewPassword = await this.encryptPassword({sessionKey: this.getSessionKey, password: this.newPassword});

        // Call Reset password API
        let results = await this.resetPassword({
          sessionKey: this.getSessionKey,
          encPassword: encNewPassword
        });
        if (results && results.result && results.resultMessage) {
          if (results.result.toUpperCase() === 'FAILURE' && results.resultMessage.indexOf('policy restrictions') > -1) {
            errorMessage = 'We are unable to reset your password.  \n"Failed to Reset Password: Password was rejected due to your organization\'s policy restrictions.  \nPlease call the Help Desk for assistance.';
            // eslint-disable-next-line no-console
            console.log('There has been an issue changing your password: ' + results.resultMessage);
            this.spinner = false;
            this.showAlertModal('Error', errorMessage, 'here')
          } else if (results.result.toUpperCase() === 'FAILURE') {
            errorMessage = 'We are unable to reset your password.  \nPlease call the Help Desk for assistance.';
            // eslint-disable-next-line no-console
            console.log('There has been an issue changing your password: ' + results.resultMessage);
            this.spinner = false;
            this.showAlertModal('Error', errorMessage, 'here')

          } else if (results.result.toUpperCase() === 'SUCCESS') {
            this.spinner = false;
            this.updateCurrentForm('FormPWR5')

          } else {
            errorMessage = 'Failed to reset your password.  \nPlease call the Help Desk for assistance.';
            // eslint-disable-next-line no-console
            console.log('There has been an issue changing your password: ' + results.resultMessage);
            this.spinner = false;
            this.showAlertModal('Error', errorMessage, 'here')
          }
        } else {
          errorMessage = 'We are unable to reset your password.  \nPlease call the Help Desk for assistance.';
          let logMessage = 'There has been an issue changing your password, please check the logs. ';
          // eslint-disable-next-line no-console
          console.log(logMessage);
          this.spinner = false;
          this.showAlertModal('Error', errorMessage, 'home')
        }
      } else {
        if (validPassword.status === false) {
          errorMessage = validPassword.message
        }
        if (errorMessage === '') {
          errorMessage = 'General Validation Failure. Please Try again.\n\nIf you continue to receive this message, please call the Help Desk for assistance.\'' + this.rules
        }
        this.spinner = false;
        this.newPassword = '';
        this.confirmPassword = '';
        this.showAlertModal('Error', errorMessage, 'here');
        document.getElementById('newPassword').focus()
      }
    },
    showAlertModal (header, text, nav) {
      this.alertNav = nav;
      this.alertHeader = header;
      this.alertText = text;
      this.$refs.alertModal.show()
    },
    hideAlertModal () {
      this.$refs.alertModal.hide()
    },
    navigateHome () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/')
    },
    navigateResetPassword () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/passwordReset')
    },
    navigateHere () {
      this.hideAlertModal()
    }
  },
  async mounted () {
    this.ruleLines = await this.getPasswordRules({accountType:this.getUserType, gradeLevel: this.getGradeLevel});
    let rules = '';
    for (let i = 0; i < this.ruleLines.length; i++) {
      rules = rules + this.ruleLines[i] + '\n'
    }
    this.rules = rules
  }
}
</script>

<style scoped>

  .form-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
  }

  h1 {
    font-weight: bold;
    color: #000;
  }

  h3 {
    color: #4A4A4A;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    color: #000;
  }

  .form-control {
    background-color: #FFF;
    margin-bottom: 20px;
  }

  button {
    margin: 15px 0px;
    background-color: #5487BA;
    width: 100px;
    font-weight: bold;
  }

  button:hover {
    background-color: #123A5D;
  }

  .instructions {
    background-color: #FFF;
    text-align: left;
    padding: 10px;
  }

  p {
    margin: 5px 0px;
  }

  #password-rules {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    text-align: left;
    font-weight: normal;
  }

  @media only screen and (max-width: 650px) {
    .form-group {
      flex-direction: column;
    }
  }
</style>
