<template>
  <section>
    <div class="form-header">
      <h1>Unlock your Account</h1>
      <h3>Step 2: Select a verification device</h3>
    </div>
    <hr>
    <div>
      <br>
      <b-form @submit="onSubmit" v-if="show" autocomplete="off">
        <b-form-group>
          <div v-for="option in options"  :key="option.text">
            <input type="radio" id="one" :value="option.value" v-model="selected">
            <label for="one"><b>{{option.text}}</b></label>
          </div>
        </b-form-group>
        <b-form-group id="methodValueLabel"
                      description="Please enter the full email or phone number selected">
          <b-form-input id="methodValue"
                        type="text"
                        v-model="methodValue"
                        required
                        placeholder="value">
          </b-form-input>
        </b-form-group>
        <div id="form-info">
          <p>A security code will be sent to the sms or email that you provided for password resets.</p>
        </div>
        <div><b-button type="submit" variant="primary">Next</b-button>
          <app-spinner v-if="spinner"/></div>
      </b-form>
      <div v-else>
        <div id="no-valid">
          <p>There are no valid contact methods associated with this Account.</p>
          <!--p>Please complete the Account Claim process to update your Contact Methods</p-->
        </div>
      </div>
    </div>
    <!-- Modal Component -->
    <b-modal ref="alertModal" :title=alertHeader :ok-only="true">
      <p class="my-4">{{alertText}}</p>
      <div slot="modal-footer" class="w-100">
        <b-button v-if="alertNav==='home'" size="sm" class="float-right" variant="primary" @click="navigateHome()">
          Okay
        </b-button>
        <b-button v-if="alertNav==='accountClaim'" size="sm" class="float-right" variant="primary" @click="navigateAccountClaim()">
          Okay
        </b-button>
        <b-button v-if="alertNav==='here'" size="sm" class="float-right" variant="primary" @click="navigateHere()">
          Okay
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
/***************************************************
 * The FormULA2 component
 *
 * Page 2 of the Unlock Account Orchestration
 *
 * Allows Choice of contact method
 * Calls sendOTPCode api
 *
 ***************************************************/

import { mapGetters, mapActions } from 'vuex'
import Spinner from '../Spinner.vue'

export default {
  name: 'FormULA2',
  components: {
    'app-spinner': Spinner
  },
  data () {
    return {
      alertHeader: '',
      alertText: '',
      alertNav: 'here',
      show: true,
      contactType: '',
      contactValue: '',
      contactData: '',
      spinner: false,
      options:[]
    }
  },
  computed: {
    ...mapGetters('formData',['getValidationDevices','getSessionKey']),
    methodValue: {
      get () { return this.$store.state.formData.formData.methodValue },
      set (value) { this.$store.commit('formData/updateMethodValue', value) }
    },
    selected: {
      get () { return this.$store.state.formData.formData.selected },
      set (value) { this.$store.commit('formData/updateSelected', value) }
    },
  },
  methods: {
    ...mapActions('formOrchestration', ['updateCurrentForm']),
    ...mapActions('formData', ['clearData', 'setSessionKey']),
    ...mapActions('localApi',['sendOtpCode']),
    async onSubmit (evt) {
      evt.preventDefault();
      this.spinner = true;

      let i;
      let selectedOption = {};
      for (i = 0; i < this.options.length; i++) {
        if (this.options[i].value === this.selected) {
          selectedOption = this.options[i]
        }
      }
      this.$store.commit('formData/updateSelected', selectedOption.value)

      //send otp
      let address = ''
      if (selectedOption.value === 'email'){
        address = this.methodValue;
      } else if (selectedOption.value === 'sms') {
        address = this.methodValue.replace(/\(|\)|-/g, '');
      }

      let params = {
        sessionKey: this.getSessionKey,
        address,
        otpType: selectedOption.value
      };
      let results = await this.sendOtpCode(params);
      if (results.result.toUpperCase() === 'FAILURE') {
        let failMessage;
        if (results.resultMessage) {
          failMessage = results.resultMessage + '\n\n If you feel you reached this message in error, please call the Help Desk for assistance.'
        } else {
          failMessage = 'General Failure: Please call the Help Desk for assistance.'
        }
        this.spinner = false;
        this.showAlertModal('Request Failure', failMessage, 'here')
      } else {
        this.$store.commit('formData/updateSelected', selectedOption.value)

        // Handle Navigation
        this.spinner = false;
        this.updateCurrentForm('FormULA3')
      }

    },
    setSelectedOptionData () {
      let i = 0;
      while (i < this.options.length) {
        if (this.options[i].type === this.selected) {
          this.contactValue = this.options[i].value;
          this.contactData = this.options[i].data
        }
        i++
      }
    },
    showAlertModal (header, text, nav) {
      this.alertNav = nav;
      this.alertHeader = header;
      this.alertText = text;
      this.$refs.alertModal.show()
    },
    hideAlertModal () {
      this.$refs.alertModal.hide()
    },
    navigateHome () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/')
    },
    navigateAccountClaim () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/accountClaim')
    },
    navigateHere () {
      this.hideAlertModal()
    }
  },
  watch: {
    form: function () {
      this.setSelectedOptionData()
    }
  },
  mounted () {
    if (this.getValidationDevices && this.getValidationDevices.length > 0) {
      this.options = this.getValidationDevices;
      this.setSelectedOptionData();
      // Set Show to true if there are validation devices, and false, if there are none
      this.show = true;
    } else {
      this.show = false;
      this.showAlertModal('Warning', 'There are no valid contact methods associated with this Account. You will be redirected to the Account Claim process to update your Contact Methods', 'accountClaim')
    }
  }
}
</script>

<style scoped>
  form-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0;
  }

  h1 {
    font-weight: bold;
    color: #000;
  }

  h3 {
    color: #4A4A4A;
  }

  form {
    display: flex;
    flex-direction: column;
  }
  #radios1 {
    display:inline-table;
    width: 60%;
    align-items: normal;
  }
  #methodValue {
    width: 320px;
  }

  .b-form-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
/*    width: 550px;
*/    color: #000;
    font-weight: normal;
  }

  button {
    margin: 15px 0px;
    background-color: #5487BA;
    width: 100px;
    font-weight: bold;
  }

  button:hover {
    background-color: #123A5D;
  }

  /*.form-control {
    width: 650px;
  }*/

  #form-info {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    text-align: left;
  }
  #no-valid {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    text-align: left;
  }
</style>
