/****************************************************
 * Host object for the forms and their current state
 *
 ****************************************************/

// Helper functions:
const loopClearNestedObj = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      loopClearNestedObj(obj[key])
    } else {
      // Clear item
      obj[key] = undefined
    }
  })
};

// initial state
const state = {
  status: undefined,
  message: undefined,
  sessionKey: '',
  formData: {
    lastName: undefined,
    userId: undefined,
    streetNumber: undefined,
    cellPhone: undefined,
    personalEmail: undefined,
    username: undefined,
    emailAddress: undefined,
    employeeId: undefined,
    validationDevices: undefined,
    methodValue: undefined,
    selected: undefined,
    resetCode: undefined
  }
};

// getters
const getters = {
  getSessionKey: function (state) { return state.sessionKey },
  getFormDataLastName: function (state) { return state.formData.lastName },
  getFormDataUserId: function (state) { return state.formData.userId },
  getFormDataStreetNumber: function (state) { return state.formData.streetNumber },
  getFormDataStatus: function (state) { return state.status },
  getFormDataMessage: function (state) { return state.message },
  getFormDataCellPhone: function (state) { return state.formData.cellPhone },
  getFormDataPersonalEmail: function (state) { return state.formData.personalEmail },
  getFormDataUsername: function (state) { return state.formData.username },
  getFormDataEmailAddress: function (state) { return state.formData.emailAddress },
  getFormDataEmployeeId: function (state) { return state.formData.employeeId },
  getValidationDevices: function (state) { return state.formData.validationDevices },
  getMethodValue: function (state) { return state.formData.methodValue },
  getSelected: function (state) { return state.formData.selected }
};

// mutations
const mutations = {
  clearData: function  (state) {
    // Clear form data
    loopClearNestedObj(state.formData);
    state.currentForm = '';
    state.currentStep = 0
  },
  setSessionKey: function  (state, sessionKeyIn) {
    state.sessionKey = sessionKeyIn
  },
  updateStatus: function (state, status) { state.status = status; },
  updateMessage: function (state, message) { state.status = message; },
  updateFormDataLastName: function (state, lastName) { state.formData.lastName = lastName; },
  updateFormDataStreetNumber: function (state, streetNumber) { state.formData.streetNumber = streetNumber; },
  updateFormDataCellPhone: function (state, cellPhone) { state.formData.cellPhone = cellPhone; },
  updateFormDataUserId: function (state, userId) {
    if(userId.indexOf('@') > -1){
      userId = userId.substring(0,userId.indexOf('@'));
    }
    state.formData.userId = userId;
  },
  updateFormDataPersonalEmail: function (state, personalEmail) { state.formData.personalEmail = personalEmail; },
  updateFormDataUsername: function (state, username) {
    if(username.indexOf('@') > -1){
      username = username.substring(0,username.indexOf('@'));
    }
    state.formData.username = username;
  },
  updateFormDataEmailAddress: function (state, emailAddress) { state.formData.emailAddress = emailAddress; },
  updateFormDataEmployeeId: function (state, employeeId) { state.formData.employeeId = employeeId; },
  updateValidationDevices: function (state, validationDevices) { state.formData.validationDevices = validationDevices; },
  updateMethodValue: function (state, methodValue) { state.formData.methodValue = methodValue; },
  updateSelected: function (state, selected) { state.formData.selected = selected; },
  updateResetCode: function (state, resetCode) { state.formData.resetCode = resetCode; },
};

// actions
const actions = {
  clearData: ({ commit }, payload) => commit('clearData', payload),
  setSessionKey: ({ commit }, payload) => commit('setSessionKey', payload),
  updateStatus: ({ commit }, payload) => commit('updateStatus', payload),
  updateMessage: ({ commit }, payload) => commit('updateMessage', payload),
  updateFormDataLastName: ({ commit }, payload) => commit('updateFormDataLastName', payload),
  updateFormDataStreetNumber: ({ commit }, payload) => commit('updateFormDataStreetNumber', payload),
  updateFormDataCellPhone: ({ commit }, payload) => commit('updateFormDataCellPhone', payload),
  updateFormDataPersonalEmail: ({ commit }, payload) => commit('updateFormDataPersonalEmail', payload),
  updateFormDataUserId: ({ commit }, payload) => commit('updateFormDataUserId', payload),
  updateFormDataUsername: ({ commit }, payload) => commit('updateFormDataUsername', payload),
  updateFormDataEmailAddress: ({ commit }, payload) => commit('updateFormDataEmailAddress', payload),
  updateFormDataEmployeeId: ({ commit }, payload) => commit('updateFormDataEmployeeId', payload),
  updateValidationDevices: ({ commit }, payload) => commit('updateValidationDevices', payload),
  updateMethodValue: ({ commit }, payload) => commit('updateMethodValue', payload),
  updateSelected: ({ commit }, payload) => commit('updateSelected', payload),
  updateResetCode: ({ commit }, payload) => commit('updateResetCode', payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}