<template>
<div>
  <form-view
      v-bind:requestedForm="initialForm"
      v-bind:orchestration="orchestration"
    >
    </form-view>
</div>
</template>

<script>
/***************************************************
 * The FormOrchestration component
 *
 * Root component for the page flow
 *
 * Bound to passed in initialForm component name
 * Bound to passed in set of forms
 *
 * Hold FormView component
 ***************************************************/
import { mapActions } from 'vuex'
import FormView from './FormView.vue'

export default {
  name: 'FormOrchestration',
  components: {
    'form-view': FormView
  },
  props: {
    orchestration: {
      type: String,
      required: true
    }
  },
  watch: {
    orchestration: function () {
      this.validateAuth();
      this.getData();
    }
  },
  created: function () {
    this.getData()
  },
  computed: {
    initialForm: {
      get () { return this.$store.state.formOrchestration.orchestrations.find(o => o.name === this.orchestration).initialForm }
    },
  },
  methods: {
    ...mapActions('formOrchestration',['updateCurrentForm', 'updateCurrentOrchestration']),
    getData () {
      this.updateCurrentOrchestration(this.orchestration);
      this.updateCurrentForm(this.initialForm);
    },
    validateAuth (){
      let token = localStorage.getItem('token');
      let expiration = localStorage.getItem('expiration');
      if(!token || token.length === 0){
        this.$log('User does not have a token');
        this.$router.push({ path: '/', query: { redirectFrom: this.initialForm } });
      }
      if(!expiration || expiration.length === 0){
        this.$log('User does not have an expiration');
        this.$router.push({ path: '/', query: { redirectFrom: this.initialForm } });
      }
      if(expiration+30 < (Math.floor(Date.now() / 1000 ))){
        this.$log('User session has expired expiration');
        this.$router.push({ path: '/', query: { redirectFrom: this.initialForm } });
      }
    }
  }
}
</script>

<style scoped>

</style>
