<template>
  <section class="form-view">
    <b-button-group v-if="windowWidth > 500">
      <form-link
        v-show="forms.length > 1"
        v-for="(form,index) in forms"
        :key="index"
        :orchestration="form.orchestration"
        :form="form.name"
        :stepNumber="form.stepText"
        :stepName="form.stepName"
        :step="form.step">
      </form-link>
    </b-button-group>
      <b-button-group v-else >
        <form-link v-if="currentForm"
          v-show="forms.length > 1"
          :orchestration="form.orchestration"
          :form="form.name"
          :stepNumber="form.stepText"
          :stepName="form.stepName"
          :step="form.step">
        </form-link>
    </b-button-group>
    <div id="currentComponent">
    <b-input-group size="lg">
      <component :is="component" :actionSet="actionSet"></component>
    </b-input-group>
    </div>
  </section>
</template>

<script>
/***************************************************
   * The FormView component
   *
   * Maintains the current page of the Orchestration
   * Step Number of the FormOrchestration is held here
   *
   * Bound to passed in orchestration name
   * Bound to passed in form name
   * Bound to the step of the orchestration
   *
   * Hold Form component(s)
   ***************************************************/
import FormLink from './FormLink.vue'

export default {
  name: 'form-view',
  data () {
    return {
      actionSet: [],
      windowWidth: 0
    }
  },
  props: {
    requestedForm: {
      type: String,
      required: true
    },
    orchestration: {
      type: String,
      required: true
    }
  },
  computed: {
    currentForm: {
      get () { return this.$store.state.formOrchestration.currentForm },
      set (value) { this.$store.commit('formOrchestration/updateCurrentForm', value) }
    },
    forms: {
      get () {
        return this.$store.state.formOrchestration.orchestrations.find(o => o.name === this.orchestration).forms
      }
    },
    component: {
      get () {
        let component =  this.forms.find(f => f.name === this.currentForm).component;
        return Object.assign({}, component);
      }
    },
    form: {
      get () { return this.forms.find(f => f.name === this.currentForm) }
    }
  },
  components: {
    'form-link': FormLink
  },
  mounted () {
    this.windowWidth = window.innerWidth;
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  }
}
</script>

<style scoped>
  .btn-group {
    width: 100%;
    background-color: #c6c5c5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  .btn-primary {
    background-color: #00456b;
    height: 55px;
    border-color: #00456b;
  }

  .btn-secondary {
    background-color: #123A5D;
    height: 55px;
    border-color: #123A5D;
  }

  button {
    margin: 0 15px;
  }

  .input-group {
    background-color: #E7E7E7;
    padding: 0 0 15px 15px;
    display: flex;
    justify-content: center;
    overflow: auto;
    position: absolute;
    top: 130px;
    bottom: 34px;
  }

  .form-view {
    height: 100px;
  }

  @media only screen and (max-width: 650px) {
    .input-group {
      overflow: auto;
      bottom: 0;
    }
  }

  @media only screen and (max-width: 390px) {
    .btn-primary {
      margin-bottom: 65px;
      margin-left: 0px;
    }
  }
</style>
