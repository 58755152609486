<template>
  <section>
    <div class="form-header">
      <h1>Reset your Password</h1>
      <h3>Step 1: Verify your information</h3>
    </div>
    <hr>
    <div>
    <b-form @submit="onSubmit" v-if="show" autocomplete="off">
      <div id="rules">
        <p>{{text}}</p>
      </div>
      <br>
      <b-form-group id="userIDLabel">
        <b-form-input id="userID"
                      type="text"
                      v-model="userId"
                      required
                      placeholder="Username / Employee Number">
        </b-form-input>
        <br>
        <div id="recaptcha" class="g-recaptcha"></div>
      </b-form-group>
      <div>
        <b-button type="submit" variant="primary">Next</b-button>
        <app-spinner v-if="spinner" ></app-spinner>
      </div>
    </b-form>
      <!-- Modal Component -->
      <b-modal ref="alertModal" :title=alertHeader :ok-only="true">
        <p class="my-4">{{alertText}}</p>
        <div slot="modal-footer" class="w-100">
          <b-button v-if="alertNav==='home'" size="sm" class="float-right" variant="primary" @click="navigateHome()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='resetPassword'" size="sm" class="float-right" variant="primary" @click="navigateResetPassword()">
            Okay
          </b-button>
          <b-button v-if="alertNav==='here'" size="sm" class="float-right" variant="primary" @click="navigateHere()">
            Okay
          </b-button>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-undef */

/***************************************************
 * The FormPR1 component
 *
 * Page 1 of the Password Reset Orchestration
 *
 * Calls lookupAccountData api
 *
 *  Find account data and populates store
 ***************************************************/

import { mapGetters, mapActions } from 'vuex'
import Spinner from '../Spinner.vue'
let clientServices = require('../../clientServices.js');

export default {
  name: 'FormPWR1',
  components: {
    'app-spinner': Spinner
  },
  data () {
    return {
      widgetId: 0,
      captchaResponse: '',
      alertHeader: '',
      alertText: '',
      alertNav: 'here',
      text: 'Please enter your username or employee number:',
      show: true,
      localFormData: {},
      spinner: false
    }
  },
  computed: {
    ...mapGetters('google', ['getSiteKey']),
    ...mapGetters('formData', ['getSessionKey']),
    userId: {
      get () { return this.$store.state.formData.formData.userId },
      set (value) { this.$store.commit('formData/updateFormDataUserId', value) }
    }
  },
  methods: {
    ...mapActions('formOrchestration', ['updateCurrentForm']),
    ...mapActions('formData', ['clearData', 'setSessionKey']),
    ...mapActions('localApi', ['accountLookup', 'newSessionKey']),
    ...mapActions('google',['checkCaptcha']),
    initReCaptcha: function () {
      let siteKey = this.getSiteKey;
      let self = this;
      setTimeout(function () {
        if (typeof grecaptcha === 'undefined') {
          self.initReCaptcha()
        } else {
          grecaptcha.render('recaptcha', {
            sitekey: siteKey,
            size: 'compact normal',
            badge: 'inline',
            callback: self.saveCaptcha
          })
        }
      }, 100)
    },
    saveCaptcha (token) {
      this.captchaResponse = token
    },
    resetCaptcha () {
      grecaptcha.reset(this.widgetId)
    },
    async onSubmit (evt) {
      evt.preventDefault();
      this.spinner = true;
      let errorMessage = '';

      let captchaClicked = true;
      if (this.captchaResponse === '') {
        captchaClicked = false
      }
      if (captchaClicked === true) {
        // Check Captcha
          let captchaParams = {
            params: {
              sessionKey: this.getSessionKey,
              response: this.captchaResponse
            }
          };

          let captchaValidation = await this.checkCaptcha(captchaParams);
          if (captchaValidation === true) {

            //Prep message and send to API
            let parameters = {
              userId: this.userId,
              sessionKey: this.getSessionKey,
              requestType: 'passwordReset'
            };
            let accountData = await this.accountLookup(parameters);
            if (accountData.result === 'FAILURE' || accountData.result === 'Failure') {
              if (accountData.resultMessage && accountData.resultMessage === 'Invalid Session Encountered') {
                await this.newSessionKey();
                this.resetCaptcha();
                this.spinner = false;
              }
              let failMessage;
              if (accountData.resultMessage) {
                failMessage = accountData.resultMessage
              } else {
                failMessage = 'General Failure'
              }
              this.spinner = false;
              this.showAlertModal('Request Failure', failMessage, 'home')
            } else {
              this.$store.commit('formData/updateValidationDevices', accountData.data.validationDevices);

              // HandleNavigation
                let toForm = 'FormPWR2';
                this.spinner = false;
                this.updateCurrentForm(toForm)
            }
          } else {
            errorMessage = 'We are unable to reset your password.\nPlease contact the Help Desk for assistance.';
            this.spinner = false;
            this.showAlertModal('Error', errorMessage, 'home')
          }
      } else {
        if (captchaClicked === false) {
          errorMessage = errorMessage + '\nPlease validate you\'re not a robot and try again.';
          if (this.widgetId === 0) {
            this.initReCaptcha()
          }
          this.spinner = false;
          this.showAlertModal('Error', errorMessage, 'here')
        }
      }
    },
    showAlertModal (header, text, nav) {
      this.alertNav = nav;
      this.alertHeader = header;
      this.alertText = text;
      this.$refs.alertModal.show()
    },
    hideAlertModal () {
      this.resetCaptcha();
      this.$refs.alertModal.hide()
    },
    navigateHome () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/')
    },
    navigateResetPassword () {
      this.hideAlertModal();
      this.clearData();
      this.$router.push('/resetPassword')
    },
    navigateHere () {
      this.resetCaptcha();
      this.hideAlertModal()
    }
  },
  async mounted () {
    this.initReCaptcha();
  },
  async created () {
    let sessionKey =  this.getSessionKey;
    // eslint-disable-next-line no-console
    console.log(sessionKey)

    if (sessionKey == null || sessionKey.length < 12 ) {
      sessionKey = await clientServices.getSessionKey();
      this.setSessionKey(sessionKey);
    }
  }
}
</script>

<style scoped>
  form-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0;
  }

  h1 {
    font-weight: bold;
    color: #000;
  }

  h3 {
    color: #4A4A4A;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
/*    width: 650px;
*/    font-weight: bold;
    color: #000;
  }

  button {
    margin: 15px 0px;
    background-color: #5487BA;
    width: 100px;
    font-weight: bold;
  }

  button:hover {
    background-color: #123A5D;
  }

  .form-control {
    /*width: 650px;*/
    background-color: #FFF;
  }

  #rules {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 1rem;
    padding: 10px;
    text-align: left;
  }
</style>
