<template>
  <div>
    <div id="notification">
      <h3>{{message}}</h3>
    </div>
    <br><br>
  </div>
</template>

<script>
/***************************************************
 * The Header component
 *
 ***************************************************/

export default {
  name: 'Header',
  props: {
    message: null
  }
}
</script>

<style scoped>
  #notification {
    background-color: chocolate;
    width: 100%;
    padding: 10px 50px;
  }
</style>
