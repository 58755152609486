/****************************************************
 * Vuex root for vuex stores
 * Exposes the stores to the rest of the application
 ****************************************************/

import Vue from 'vue';
import Vuex from 'vuex';
import formOrchestration from './modules/formOrchestration';
import formData from './modules/formData';
import google from './modules/google'
import localApi from './modules/localApi'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    formOrchestration,
    formData,
    google,
    localApi
  },
  strict: debug
})
