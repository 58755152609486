/****************************************************
 * Vuex store for form orchestration objects
 ****************************************************/

import FormAC1 from '../../components/forms/FormAC1.vue'
import FormAC2 from '../../components/forms/FormAC2.vue'
import FormAC3 from '../../components/forms/FormAC3.vue'
import FormAC4 from '../../components/forms/FormAC4.vue'
import FormPWR1 from '../../components/forms/FormPWR1.vue'
import FormPWR2 from '../../components/forms/FormPWR2.vue'
import FormPWR3 from '../../components/forms/FormPWR3.vue'
import FormPWR4 from '../../components/forms/FormPWR4.vue'
import FormPWR5 from '../../components/forms/FormPWR5.vue'
import FormULA1 from '../../components/forms/FormULA1.vue'
import FormULA2 from '../../components/forms/FormULA2.vue'
import FormULA3 from '../../components/forms/FormULA3.vue'
import FormULA4 from '../../components/forms/FormULA4.vue'

// initial state
const state = {
  currentForm: '',
  currentOrchestration: '',
  orchestrations: [
    {
      name: 'accountClaim',
      initialForm: 'FormAC1',
      forms: [
        {name: 'FormAC1', stepText: 'Step 1', step: 1, stepName: 'Lookup Account', component: FormAC1, orchestration: 'accountClaim'},
        {name: 'FormAC2', stepText: 'Step 2', step: 2, stepName: 'Set Password', component: FormAC2, orchestration: 'accountClaim'},
        {name: 'FormAC3', stepText: 'Step 3', step: 3, stepName: 'Account Recovery Information', component: FormAC3, orchestration: 'accountClaim'},
        {name: 'FormAC4', stepText: 'Account Resources', step: 4, stepName: 'Login, Email...', component: FormAC4, orchestration: 'accountClaim'}
      ]
    },
    {
      name: 'passwordReset',
      initialForm: 'FormPWR1',
      forms: [
        {name: 'FormPWR1', stepText: 'Step 1', step: 1, stepName: 'Lookup Account', component: FormPWR1, orchestration: 'passwordReset'},
        {name: 'FormPWR2', stepText: 'Step 2', step: 2, stepName: 'Select a Device', component: FormPWR2, orchestration: 'passwordReset'},
        {name: 'FormPWR3', stepText: 'Step 3', step: 3, stepName: 'Validate Security Code', component: FormPWR3, orchestration: 'passwordReset'},
        {name: 'FormPWR4', stepText: 'Step 4', step: 4, stepName: 'Set Your New Password', component: FormPWR4, orchestration: 'passwordReset'},
        {name: 'FormPWR5', stepText: 'Finish', step: 5, stepName: '', component: FormPWR5, orchestration: 'passwordReset'}
      ]
    },
    {
      name: 'unlockAccount',
      initialForm: 'FormULA1',
      forms: [
        {name: 'FormULA1', stepText: 'Step 1', step: 1, stepName: 'Lookup Account', component: FormULA1, orchestration: 'unlockAccount'},
        {name: 'FormULA2', stepText: 'Step 2', step: 2, stepName: 'Select a Device', component: FormULA2, orchestration: 'unlockAccount'},
        {name: 'FormULA3', stepText: 'Step 3', step: 3, stepName: 'Validate Security Code', component: FormULA3, orchestration: 'unlockAccount'},
        {name: 'FormULA4', stepText: 'Finish', step: 4, stepName: '', component: FormULA4, orchestration: 'unlockAccount'}
      ]
    }
  ]
};

// getters
const getters = {
  getOrchestrations: function  (state) {
    return state.orchestrations
  },
  getCurrentForm: function  (state) {
    return state.currentForm;
  },
  getCurrentStep: function  (state) {
    let orchestration = state.orchestrations.find(o=>o.name === state.currentOrchestration);
    if(orchestration && orchestration.forms) {
      return orchestration.forms.find(f => f.name === state.currentForm).step;
    } else {
      return 1
    }
  }
};

// mutations
const mutations = {
  updateCurrentForm: function  (state, formName) {
    state.currentForm = formName;
  },
  updateCurrentOrchestration: function  (state, orchestration) {
    state.currentOrchestration = orchestration;
  }
};

const actions = {
  updateCurrentForm: ({commit}, payload) => commit('updateCurrentForm', payload),
  updateCurrentOrchestration: ({commit}, payload) => commit('updateCurrentOrchestration', payload)
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
