/****************************************************
 * Configuration points for the application
 * Api_ items effect where the server gets called
 ****************************************************/

module.exports = {
  API_PROTOCOL: "https",
  API_HOST: "me.rv.com",
  API_PORT: "443",
  API_PATH: "",
  FINISHED_LOCATION: '/',
  GOOGLE_SITE_KEY: '6LdBw74UAAAAAKYwWWGYSMip0xR-38Ul8UIAGYyq'
};
