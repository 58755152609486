<template>
  <header id="logo">
    <router-link to="/"><img src="../../public/assets/cwi_logo.png" height="65" /> </router-link>
  </header>
</template>

<script>
/***************************************************
 * The Header component
 *
 ***************************************************/

export default {
  name: 'Header'
}
</script>

<style scoped>
header {
  display: flex;
  padding-left: 20px;
  background-color: #00669e;
}

@media only screen and (max-width: 610px) {
  img {
    display: none;
  }
}
</style>

