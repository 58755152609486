<template>
  <section class="main-view">
    <div class="form-header">
      <h1>Choose your password</h1>
    </div>
    <hr>
    <b-form @submit="onSubmit" v-if="show" autocomplete="off">
      <!--div id="form-header">
        <p>{{text}}</p>
      </div-->
      <section class="main-form">
        <br>
        <b-container fluid>
          <b-row class="my-1">
            <b-col sm="5">
              <label for="input-new-password">New Password:</label>
            </b-col>
            <b-col sm="7">
              <b-form-input id="input-new-password" size="sm" v-model="newPassword" required placeholder="New Password" type="password"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col sm="5">
              <label for="input-confirm-password">Confirm Password:</label>
            </b-col>
            <b-col sm="7">
              <b-form-input id="input-confirm-password" size="sm" v-model="confirmPassword" required placeholder="Confirm Password" type="password"></b-form-input>
            </b-col>
          </b-row>
        </b-container>
        <br>
        <div>
          <b-button type="submit" variant="primary" :disabled="spinner">Submit</b-button>
          <app-spinner v-if="spinner"/>
        </div>
        <br>
        <section id="password-rules">
          <p>{{ruleLines[0]}}</p>
          <p>{{ruleLines[1]}}</p>
          <p>{{ruleLines[2]}}</p>
          <p>{{ruleLines[3]}}</p>
          <p>{{ruleLines[4]}}</p>
          <p>{{ruleLines[5]}}</p>
          <p>{{ruleLines[6]}}</p>
        </section>
        <br>
      </section>
    </b-form>
    <!-- Modal Component -->
    <b-modal ref="alertModal" :title=alertHeader :ok-only="true">
      <p class="my-4">{{alertText}}</p>
      <div slot="modal-footer" class="w-100">
        <b-button v-if="alertNav==='home'" size="sm" class="float-right" variant="primary" @click="navigateHome()">
          Okay
        </b-button>
        <b-button v-if="alertNav==='resetPassword'" size="sm" class="float-right" variant="primary" @click="navigateResetPassword()">
          Okay
        </b-button>
        <b-button v-if="alertNav==='here'" size="sm" class="float-right" variant="primary" @click="navigateHere()">
          Okay
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
  /***************************************************
   * The FormPR2 component
   *
   * Page 2 of the Password Reset Orchestration
   *
   * Calls resetPassword api
   *
   ***************************************************/

  import { mapGetters, mapActions } from 'vuex'
  import Spinner from '../Spinner.vue'

  export default {
    name: 'FormAC2',
    components: {
      'app-spinner': Spinner
    },
    data () {
      return {
        alertHeader: '',
        alertText: '',
        alertNav: 'here',
        newPassword: '',
        confirmPassword: '',
        text: 'Please select your new password:',
        show: true,
        localFormData: {},
        spinner: false,
        ruleLines: [],
        rules: 'Password must be at least 8 characters and contain at least: ' +
                '\none uppercase letter, one lowercase letter, one numeric, and 2 alphabetic. ' +
                '\nPassword may not contain username, first name, or last name.'
      }
    },
    computed: {
      ...mapGetters('formData',['getSessionKey'])
    },
    methods: {
      ...mapActions('formOrchestration',['updateCurrentForm']),
      ...mapActions('formData',['clearData']),
      ...mapActions('localApi',['validatePasswordRules', 'encryptPassword', 'getPasswordRules','resetPassword']),
      async onSubmit (evt) {
        evt.preventDefault();

        this.spinner = true;

        // Validate against password rules
        let validPassword = await this.validatePasswordRules({
          password: this.newPassword,
          confirmPassword: this.confirmPassword,
        });

        let errorMessage = '';

        if (validPassword.status === true) {
          // Call Soap action
          const encNewPassword = await this.encryptPassword({sessionKey: this.getSessionKey, password: this.newPassword});

          let data = {
            sessionKey: this.getSessionKey,
            encPassword: encNewPassword
          };

          // Call Reset password API
          let results = await this.resetPassword(data);
          if (results && results.result && results.resultMessage) {
              if (results.result.toUpperCase() === 'FAILURE' && results.resultMessage.indexOf('policy restrictions') > -1) {
                errorMessage = 'We are unable to reset your password.  \n"Failed to Reset Password: Password was rejected due to your organization\'s password policy restrictions.';
                // eslint-disable-next-line no-console
                console.log('There has been an issue changing your password: ' + results.resultMessage);
                this.spinner = false;
                this.showAlertModal('Error', errorMessage, 'here');
              } else if (results.result.toUpperCase() === 'FAILURE' && results.resultMessage.indexOf('password requirements') > -1) {
                errorMessage = 'Password entered does not meet criteria. Please Try again.';
                // eslint-disable-next-line no-console
                console.log('There has been an issue changing your password: ' + results.resultMessage);
                this.spinner = false;
                this.showAlertModal('Error', errorMessage, 'here');
              } else if (results.result.toUpperCase() === 'FAILURE') {
                errorMessage = 'We are unable to reset your password.  \nplease contact the Help Desk for assistance.';
                // eslint-disable-next-line no-console
                console.log('There has been an issue changing your password: ' + results.resultMessage);
                this.spinner = false;
                this.showAlertModal('Error', errorMessage, 'here');
              } else if (results.result.toUpperCase() === 'SUCCESS') {

                // save email, username, and employeeID for later use
                if(results.data.username) this.$store.commit('formData/updateFormDataUsername', results.data.username);
                if(results.data.mail) this.$store.commit('formData/updateFormDataEmailAddress', results.data.mail);
                if(results.data.employeeId) this.$store.commit('formData/updateFormDataEmployeeId', results.data.employeeId);

                this.spinner = false;
                this.updateCurrentForm('FormAC3');
              } else {
                errorMessage = 'Failed to reset your password.  \nplease contact the Help Desk for assistance.';
                // eslint-disable-next-line no-console
                console.log('There has been an issue changing your password: ' + results.resultMessage);
                this.spinner = false;
                this.showAlertModal('Error', errorMessage, 'here');
              }
            } else {
              errorMessage = 'We are unable to reset your password.  \nplease contact the Help Desk for assistance.';
              let logMessage = 'There has been an issue changing your password, please check the logs. ';
              // eslint-disable-next-line no-console
              console.log(logMessage);
              this.spinner = false;
              this.showAlertModal('Error', errorMessage, 'home');
            }
        } else {
          if (validPassword.status === false) {
            errorMessage = validPassword.message
          }
          if (errorMessage === '') {
            errorMessage = 'General Validation Failure. Please Try again.\n\nIf you continue to receive this message, please contact the Help Desk for assistance..'
          }
          this.spinner = false;
          this.newPassword = '';
          this.confirmPassword = '';
          this.showAlertModal('Error', errorMessage, 'here');
          document.getElementById('input-new-password').focus();
        }
      },
      showAlertModal (header, text, nav) {
        this.alertNav = nav;
        this.alertHeader = header;
        this.alertText = text;
        this.$refs.alertModal.show()
      },
      hideAlertModal () {
        this.$refs.alertModal.hide()
      },
      navigateHome () {
        this.hideAlertModal();
        this.clearData();
        this.$router.push('/')
      },
      navigateHere () {
        this.hideAlertModal()
      }
    },
    async mounted () {
      document.getElementById('input-new-password').focus();
    },
    async created () {
      this.ruleLines = await this.getPasswordRules();
      let rules = '';
      for (let i = 0; i < this.ruleLines.length; i++) {
        rules = rules + this.ruleLines[i] + '\n'
      }
      this.rules = rules
    }
  }
</script>

<style scoped>

  .form-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 35px 0 25px;
  }

  section {
    width: 65%
  }

  h1 {
    font-weight: bold;
    color: #000;
  }

  h3 {
    color: #4A4A4A;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    color: #000;
  }

  .form-control {
    background-color: #FFF;
    margin-bottom: 20px;
  }

  button {
    margin: 15px 0px;
    background-color: #5487BA;
    width: 100px;
    font-weight: bold;
  }

  button:hover {
    background-color: #123A5D;
  }

  p {
    margin-bottom: 5px;
    margin-left: 10px;
  }

  #password-rules {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 1rem;
    padding: 2px;
    margin: 0 0 0 0;
    text-align: left;
    font-weight: normal;
  }

  #form-header {
    background-color: #f5f5f5;
    width: 100%;
    font-size: 1rem;
    padding: 10px 10px 0 10px;
    text-align: left;
  }

  @media only screen and (max-width: 650px) {
    .form-group {
      flex-direction: column;
    }
    #password-rules {
      font-size: .5rem;
    }
  }

  a.support {
    color: #1F578A;
    font-size: small;
    text-decoration: none;
    float: right;
    position: absolute;
    right: 50px;
    bottom: 5px;
  }

  a.support:hover
  {
    color: #123A5D;
    text-decoration: underline;
  }
</style>
