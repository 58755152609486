/****************************************************
 * Vue Router for directing traffic to Vue components
 * / home
 * /:orchestration -- chooses component based on passed orchestration name
 ****************************************************/

import Vue from 'vue'
import Router from 'vue-router'
import MainPage from './../components/Main.vue'
import FormOrchestration from '../components/formOrchestration/FormOrchestration.vue'
import config from './../config.js'

const showEnvHeader = config.SHOW_ENV_HEADER || null;
const finishedLocation = config.FINISHED_LOCATION || '/';

Vue.use(Router);
export default new Router({
  routes: [
    {path: '/', name: 'MainPage', component: MainPage, props: {showEnvHeader: showEnvHeader}},
    {path: '/finished', name: 'finished', beforeEnter() {location.href = finishedLocation }},
    {path: '/:orchestration', name: 'FormOrchestration', component: FormOrchestration, props: true}
  ]
});
